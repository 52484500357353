.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}





.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/*app.js*/
.hanziItem {
  width: 90%;
  margin: 10px auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  line-height: 40px;
  text-align: left;
  text-indent: 2em;
}

.class_header {
  background-color: #ffdc00;
  height: 150px;
}

.item_ClassName {
  width: 90%;
  margin: auto;
  line-height: 40px;
  font-weight: bold;
}

.pinyin {
  text-align: center;
  font-size: 30px;
}